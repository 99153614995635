<template>
  <div class="container-sm container-md" id="school">
    <div class="card">
      <a-tabs defaultActiveKey="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bars" />List
          </span>
          <school-list></school-list>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="plus" />Create
          </span>
          <create-school></create-school>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import SchoolList from '@/components/School/List.vue';
import CreateSchool from '@/components/School/Create.vue';
export default {
  components: {
    SchoolList,
    CreateSchool
  },
  created() {
    this.$store.commit('setActiveNavText', '1');
  }
}
</script>
<style scoped>
.card {
  box-shadow: 0 0px 3px rgba(0,0,0,0.12), 0 0px 2px rgba(0,0,0,0.24);
  margin-top: 20px;
  padding: 30px;
}
</style>