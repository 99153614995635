<template>
  <a-table
    :columns="columns" 
    :dataSource="schoolList" 
    :pagination="false"
    :loading="tableLoading"
    rowKey="id">
      <span slot="status" slot-scope="tag">
        <a-tag :color="(tag === 1 ? 'green' : 'volcano')">
          {{tag === 1 ? 'Active' : 'Deleted'}}
        </a-tag>
      </span>
      <span slot="action" slot-scope="record">
        <a-popconfirm
          title="Are you sure delete this school?"
          @confirm="deleteSchool(record.id)"
          okText="Yes"
          cancelText="No">
            <a href="#">Delete</a>
        </a-popconfirm>
      </span>
  </a-table>
</template>
<script>
import schoolService from '@/services/school';

const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'School Name',
      dataIndex: 'Name',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' },
    },
  ];

export default {
  data(){
    return {
      schoolList: [],
      columns,
      tableLoading: false,
    }
  },
  created() {
    this.setSchoolList();
  },
  methods: {
    async setSchoolList() {
      this.tableLoading = true;
      const result = await schoolService.getSchoolList();
      this.schoolList = result;
      this.tableLoading = false;
    },
    async deleteSchool(schoolId){
      const result = await schoolService.deleteSchool(schoolId);
      if(result) this.$message.success('School Deleted!'); 
    }
  }
}
</script>
<style scoped>

</style>