<template>
  <a-form :form="form" @submit.prevent="validateSchool" class="mt-3">
    <a-form-item label="Name" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
      <a-input placeholder="Name" v-decorator="['Name', { rules: [{ required: true, message: 'Please input a school name!'}] }]"></a-input>
    </a-form-item>
    <a-form-item label="Status" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 10}">
      <a-select
        placeholder="Select a Status"
        v-decorator="[
            'Status',
            { initialValue: 1 },
            { rules: [{ required: true, message: 'Please select a status!' }] },
          ]">
        <a-select-option :value="1">
            Active
        </a-select-option>
        <a-select-option :value="0">
            Deleted
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ xs: 24, md: {span: 3, offset: 6 }}">
      <a-button htmlType="submit" type="primary" shape="round" :block="true">Add</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import schoolService from '@/services/school';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'school' });
  },
  methods: {
    validateSchool() {
      this.form.validateFields( (err, values) => {
        if (!err) {
          this.createSchool(values);
        }
      });
    },
    async createSchool(values) {
      const result = await schoolService.createSchool(values);
      if(result.data) {
        this.$message.success('School Created!');
        this.form.resetFields();
      } else {
        this.$message.error(result.response.data);
      }
    }
  }
}
</script>
<style scoped>

</style>